import { InputHTMLAttributes, FC } from 'react'
import { useFormContext, FieldValues } from 'react-hook-form'
import { FieldByType } from '../FieldByType'
import { Checkbox as LibsCheckbox } from '@lifedot/atoms/Checkbox'

type CheckboxProps<T extends FieldValues> = (
  | {
      name: FieldByType<T, boolean>
    }
  | {
      name: FieldByType<T, string[]>
      value: string
    }
) &
  Omit<InputHTMLAttributes<HTMLInputElement>, 'name' | 'value'>

export const Checkbox = <T extends FieldValues = never>({
  name,
  ...props
}: CheckboxProps<T>): ReturnType<FC> => {
  const { register } = useFormContext()
  return <LibsCheckbox {...register(name)} {...props} />
}
