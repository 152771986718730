import { useFormState } from 'react-hook-form'
export const useErrorMessage = ():
  | {
      isSubmitted: false
    }
  | { isSubmitted: true; errorMessages: string[] } => {
  const { errors, isSubmitted } = useFormState()

  if (isSubmitted)
    return {
      isSubmitted,
      errorMessages: Object.values(errors)
        .map(getErrorMessage)
        .filter((v): v is string | string[] => v !== null)
        .flat()
    }

  return {
    isSubmitted
  }
}

const getErrorMessage = (error: unknown): string | string[] | null => {
  if (Array.isArray(error)) {
    return error
      .map(getErrorMessage)
      .reduce<string[]>((errors, currentError) => {
        if (currentError === null) return errors
        if (Array.isArray(currentError)) return [...errors, ...currentError]
        return [...errors, currentError]
      }, [])
  }

  if (typeof error === 'object' && error !== null) {
    if ('message' in error) {
      return (error as { message: string }).message
    } else {
      return getErrorMessage(Object.values(error))
    }
  }

  return null
}
