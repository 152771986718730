import { FC } from 'react'
import { css } from '@emotion/react'
import Link from 'next/link'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'
import { Button } from '@lifedot/atoms/Button'
import { ButtonLabel } from '@lifedot/components/ButtonLabel'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  root: css({
    textAlign: 'center'
  }),
  anchor: css({
    color: palette.main03
  }),
  sp: css({
    display: 'none',
    [mq('sp')]: {
      display: 'block'
    }
  }),
  button: css({
    maxWidth: 480,
    margin: '20px auto 0'
  })
}

export const SubmitButtonWithAgreement: FC = () => {
  return (
    <div css={styles.root}>
      <p css={typography.textS}>
        ライフドットの
        <Link href="/agreement/" passHref prefetch={false}>
          <a css={styles.anchor}>利用規約</a>
        </Link>
        、
        <a
          css={styles.anchor}
          href="https://life-design.a-tm.co.jp/privacy/"
          target="_blank"
          rel="noopener noreferrer"
        >
          プライバシーポリシー
        </a>
        <br css={styles.sp} />
        および
        <a
          css={styles.anchor}
          href="https://life-design.a-tm.co.jp/agreement/"
          target="_blank"
          rel="noopener noreferrer"
        >
          個人関連情報の受領と利用について
        </a>
        に同意の上で
        <br css={styles.sp} />
        ご利用ください。
      </p>
      <div css={styles.button}>
        <Button
          mainText={
            <>
              <span css={typography.textXL}>この内容で問い合わせる</span>
              <ButtonLabel>無料</ButtonLabel>
            </>
          }
          type="submit"
          color="accent"
        />
      </div>
    </div>
  )
}
