import { FC } from 'react'
import * as Yup from 'yup'
import { typography } from '@lifedot/styles/typography'
import { Checkbox } from '@/components/Input/Checkbox'
import { FormItemFrame } from '@/components/FormItemFrame'
import { OptionalLabel } from '@/components/OptionalLabel'
import { css } from '@emotion/react'
import { EventTracker } from '@lifedot/tracking'

const styles = {
  label: css({
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: '24px 1fr',
    gap: 8,
    cursor: 'pointer'
  })
}

export const mailMagazineSchema = {
  accepted_information_mail: Yup.boolean().required().default(false)
}

const yupObject = Yup.object(mailMagazineSchema)
type MailMagazineFormValues = Yup.InferType<typeof yupObject>

export const MailMagazine: FC = () => {
  return (
    <FormItemFrame
      title={
        <>
          <p css={typography.textL}>
            <b>ライフドットからのおしらせ</b>
          </p>
          <OptionalLabel />
        </>
      }
    >
      <label css={styles.label}>
        <EventTracker
          label="receiveInformationMail"
          action="click"
          elementLabel="receive_information_mail"
        >
          <Checkbox<MailMagazineFormValues> name="accepted_information_mail" />
        </EventTracker>
        <span css={typography.textM}>
          当社から新着、おすすめ霊園情報を受け取る
        </span>
      </label>
    </FormItemFrame>
  )
}
