import { FC } from 'react'
import { palette } from '@lifedot/styles/palette'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'

const styles = {
  text: css({
    color: palette.gray6,
    paddingLeft: '1em',
    '::before': {
      content: '"※"',
      marginLeft: '-1em'
    }
  })
}

type SupplementTextProps = {
  text: string
}

export const SupplementText: FC<SupplementTextProps> = ({ text }) => {
  return <p css={[typography.textS, styles.text]}>{text}</p>
}
