import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

const styles = {
  label: css({
    width: 48,
    textAlign: 'center',
    flexShrink: 0,
    color: palette.gray6,
    backgroundColor: palette.gray2
  })
}

export const OptionalLabel: FC = () => {
  return <span css={[typography.textM, styles.label]}>任意</span>
}
