import { FC } from 'react'
import { css } from '@emotion/react'
import { typography } from '@lifedot/styles/typography'
import { useFormContext } from 'react-hook-form'
import { TextInput } from '@/components/Input/TextInput'
import { ErrorMessage } from '@/components/Input/ErrorMessage'
import * as Yup from 'yup'
import { RequiredLabel } from '@/components/RequiredLabel'
import { FormItemFrame } from '@/components/FormItemFrame'
import { EventTracker } from '@lifedot/tracking'
import { SupplementText } from '../SupplementText'

const styles = {
  supplement: css({
    marginTop: 8
  })
}

export const userEmailSchema = {
  email: Yup.string()
    .required('メールアドレスを入力してください')
    .matches(
      /^[\w+\-.]+@[a-z\d\-.]+\.[a-z]+$/,
      '正しいメールアドレスではありません'
    )
    .max(175, 'メールアドレスは175文字以内で入力してください')
}

const yupObject = Yup.object(userEmailSchema)
type UserEmailFormValues = Yup.InferType<typeof yupObject>

export const UserEmail: FC = () => {
  const { watch } = useFormContext<UserEmailFormValues>()
  const email = watch('email')
  const allInputted = userEmailSchema.email.isValidSync(email)
  return (
    <FormItemFrame
      title={
        <>
          <p css={typography.textL}>
            <b>メールアドレス</b>
          </p>
          <RequiredLabel allInputted={allInputted} />
        </>
      }
    >
      <EventTracker label="email" action="click" elementLabel="email">
        <TextInput<UserEmailFormValues>
          name="email"
          id="email"
          placeholder="例：ohaka@example.ne.jp"
          type="email"
        />
      </EventTracker>
      <ErrorMessage<UserEmailFormValues> name="email" />
      <div css={styles.supplement}>
        <SupplementText text="ご入力いただいたアドレス宛に問い合わせ完了通知メールをお送りします。" />
      </div>
    </FormItemFrame>
  )
}
