import { FC } from 'react'
import { css } from '@emotion/react'
import { mq } from '@lifedot/styles/mediaQuery'
import Image from 'next/image'
import { typography } from '@lifedot/styles/typography'
import { Divider } from '@lifedot/atoms/Divider'

const styles = {
  list: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1px 1fr',
    gap: 20,
    maxWidth: 700,
    margin: 'auto',
    [mq('sp')]: {
      gridTemplateColumns: '1fr',
      // 東証ロゴ利用規定で5mm以上の余白が必要
      gap: '2.5mm'
    },
    li: {
      display: 'grid',
      gridTemplateColumns: '34px 1fr',
      gap: 8,
      alignItems: 'center'
    }
  })
}

export const JPXAndSSL: FC = () => {
  return (
    <ul css={styles.list}>
      <li>
        <Image
          src="https://s3.lifedot.jp/uploads/ending/path/74380/logo_ssl.png"
          width={34}
          height={40}
          alt=""
          objectFit="contain"
        />
        <p css={typography.textXS}>
          当サイトではプライバシー保護のため、「SSL暗号化通信」を実現しています。
        </p>
      </li>
      <Divider orientation="vertical" dark />
      <li>
        <Image
          src="https://s3.lifedot.jp/uploads/ending/path/72527/logo_jpx.png"
          width={34}
          height={40}
          alt=""
          objectFit="contain"
        />
        <p css={typography.textXS}>
          ライフドットは株式会社エイチーム（東証プライム市場上場）のグループ企業のサービスです。
        </p>
      </li>
    </ul>
  )
}
