import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

const styles = {
  label: css({
    width: 48,
    textAlign: 'center',
    flexShrink: 0,
    display: 'inline-block'
  }),
  ok: css({
    color: palette.white,
    backgroundColor: palette.accent
  }),
  required: css({
    color: palette.white,
    backgroundColor: palette.alert
  })
}

interface RequiredLabelProps {
  allInputted: boolean
}

export const RequiredLabel: FC<RequiredLabelProps> = ({ allInputted }) => {
  return (
    <span
      css={[
        typography.textM,
        styles.label,
        allInputted ? styles.ok : styles.required
      ]}
    >
      {allInputted ? 'OK' : '必須'}
    </span>
  )
}
