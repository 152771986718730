import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'
import { typography } from '@lifedot/styles/typography'
import { useErrorMessage } from './dependencies'

const styles = {
  box: css({
    border: `solid 2px ${palette.alert}`,
    padding: '16px 16px 8px',
    [mq('sp')]: {
      padding: '12px 12px 4px'
    }
  }),
  title: css({
    color: palette.alert,
    fontWeight: 'bold',
    marginBottom: 12
  }),
  error: css({
    display: 'inline-block',
    color: palette.alert,
    border: `solid 1px ${palette.alert}`,
    backgroundColor: palette.white,
    borderRadius: 4,
    padding: '4px 8px',
    marginRight: 8,
    marginBottom: 8
  })
}

export const ErrorMessageBox: React.FC = () => {
  const errorMessageState = useErrorMessage()

  if (
    !errorMessageState.isSubmitted ||
    errorMessageState.errorMessages.length < 1
  )
    return null

  return (
    <div css={styles.box}>
      <p css={[typography.textL, styles.title]}>以下の項目を確認してください</p>
      <p>
        {errorMessageState.errorMessages.map((errorMessage, index) => (
          <span key={index} css={[typography.textS, styles.error]}>
            {errorMessage}
          </span>
        ))}
      </p>
    </div>
  )
}
