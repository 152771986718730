import { InputHTMLAttributes, FC } from 'react'
import { useFormContext, FieldValues, useFormState, get } from 'react-hook-form'
import { FieldByType } from '@/components/Input/FieldByType'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'

const styles = {
  input: css({
    width: '100%',
    color: palette.black,
    border: `2px solid ${palette.gray4}`,
    borderRadius: 4,
    padding: '12px 8px',
    '&::placeholder': {
      color: palette.gray4
    }
  }),
  error: css({
    backgroundColor: 'rgba(225, 57, 77, 0.1)'
  })
}

export interface TextInputProps<T extends FieldValues>
  extends Omit<
    InputHTMLAttributes<HTMLInputElement>,
    'name' | 'onChange' | 'onBlur' | 'type'
  > {
  name: FieldByType<T, string | undefined>
  type?: 'text' | 'email' | 'tel'
}

export const TextInput = <T extends FieldValues = never>({
  name,
  type = 'text',
  ...props
}: TextInputProps<T>): ReturnType<FC> => {
  const { register } = useFormContext()
  const { errors } = useFormState()
  const error = get(errors, name)

  return (
    <input
      type={type}
      css={[styles.input, error && styles.error]}
      {...register(name)}
      {...props}
    />
  )
}
