import { ReactNode, FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { mq } from '@lifedot/styles/mediaQuery'

const styles = {
  root: css({
    display: 'grid',
    gridTemplateColumns: '1fr 3fr',
    border: `1px solid ${palette.gray3}`,
    [mq('sp')]: {
      gridTemplateColumns: '1fr'
    },
    dt: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      backgroundColor: palette.background,
      padding: '8px 12px',
      borderRight: `1px solid ${palette.gray3}`,
      [mq('sp')]: {
        borderRight: 'none',
        borderBottom: `1px solid ${palette.gray3}`
      }
    },
    dd: {
      padding: 16,
      backgroundColor: palette.white,
      [mq('sp')]: {
        padding: 12
      }
    }
  })
}

interface FormItemFrameProps {
  title: ReactNode
  children: ReactNode
}

export const FormItemFrame: FC<FormItemFrameProps> = ({ title, children }) => {
  return (
    <dl css={styles.root}>
      <dt>{title}</dt>
      <dd>{children}</dd>
    </dl>
  )
}
