import { FC } from 'react'
import { css } from '@emotion/react'
import { palette } from '@lifedot/styles/palette'
import { typography } from '@lifedot/styles/typography'

const styles = {
  heading: css({
    borderLeft: `8px solid ${palette.main}`,
    paddingLeft: 8
  })
}

type HeadingProps = {
  text: string
}

export const Heading: FC<HeadingProps> = ({ text }) => {
  return <h2 css={[typography.textL, styles.heading]}>{text}</h2>
}
